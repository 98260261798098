<template>
    <zw-sidebar @shown="shown"
                :title="this.payload.id ? $t('common.form.title.editShippingRule')  + ' ('+ this.payload.id + ')' : $t('common.form.title.createShippingRule')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="6">
                        <zw-select-group v-model="form.shipping_method"
                                         :options="getMethods() | optionsVV"
                                         name="method"
                                         :label-prefix="labelPrefix"
                                         validate="required"
                        ></zw-select-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-switch-group v-model="form.premium"
                                         name="premium"
                                         :label-prefix="labelPrefix"
                        ></zw-switch-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.order"
                                        name="order"
                                        :label-prefix="labelPrefix"
                                        type="number"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">{{ $t('settings.shipping_rule.label.attribute') }}</b-col>
                    <b-col cols="3">{{ $t('settings.shipping_rule.label.condition') }}</b-col>
                    <b-col cols="3">{{ $t('settings.shipping_rule.label.value') }}</b-col>
                    <b-col cols="2"></b-col>
                </b-row>

                <condition v-for="(rule,index) in form.conditions"
                           :key="index"
                           :index="index"
                           v-model="form.conditions[index]"
                           :attributes="getAttributes() | optionsVV"
                           :conditions="getConditions()"
                           :time-types="getTimeTypes()"
                           :remove="remove"
                           :attributeModel="'offering'"
                ></condition>

                <b-row>
                    <b-col cols="1">
                        <b-button variant="primary"
                                  @click="addItem"
                                  class="mb-2"
                        >
                            <font-awesome-icon icon="plus"/>
                        </b-button>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right mt-4">
                        <zw-model-selector-group v-model="form.offering_id"
                                                 name="example"
                                                 :label-prefix="labelPrefix"
                                                 model-type="offering"
                                                 :callback="offeringSelected"
                                                 disable-label
                        />
                    </b-col>
                </b-row>
                <template v-if="example">

                    <b-row>
                        <b-col class="text-center">
                            <h3>
                                {{ $t('common.column.sale.number') }} {{ example.label }}
                            </h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <table style="width: 100%;">
                                <template v-for="(item, key) in example.rules_data">
                                    <tr v-if="key != 'category'">
                                        <td class="text-center col-3">
                                            <label>{{ key }}</label>
                                        </td>
                                        <td>
                                            <zw-input-group
                                                :value="item"
                                                :name="key"
                                                :disableLabel="true"
                                                :readonly="true"
                                            ></zw-input-group>
                                        </td>
                                    </tr>
                                </template>
                            </table>
                        </b-col>
                    </b-row>
                </template>


            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Condition from "@/components/Condition";
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'ShippingRuleModal',
    components: {ZwModelSelectorGroup, Condition},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                offering_id: 0,
                conditions: [],
                premium: '',
                method: '',
            },
            example: '',
            offeringSearchUrl: window.apiUrl + '/offering/search-for-rules',
            form: {},
            labelPrefix: 'settings.shipping_rule.label.',
        }
    },
    methods: {
        ...mapGetters('ShippingRule', ['getRule', 'getMethods', 'getConditions', 'getAttributes', 'getTimeTypes']),
        shown() {
            const rule = this.$store.dispatch('ShippingRule/fetchRule', this.payload.id)
            const categories = this.$store.dispatch('Category/fetchOptions') // todo: move to condition component?

            Promise.all([rule, categories])
                .then(() => {
                    this.form = {
                        ...this.defaultForm,
                        ...JSON.parse(JSON.stringify(this.getRule()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('ShippingRule/saveRule', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        offeringSelected(offering) {
            this.example = offering;
        },
        addItem() {
            this.form.conditions.push({})
        },
        remove(index) {
            this.form.conditions = this.form.conditions.filter((data, i) => {
                return i != index
            })
        },
    }
}
</script>